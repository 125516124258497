.tab-background {
  background: #ffffff;
  background-size: cover;
  padding: 24px 32px;
  box-sizing: border-box;
  overflow: auto;
}

.sidebar-button {
  font-weight: 300;
  color: #ffffff70;
}

.sidebar-button.active {
  color: #ffffff;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: unset;
}
